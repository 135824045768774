import React from 'react'
import { TrackingContainer, TrackingContainerProps } from '@mc/visibility'
import { PersonaRowViewedProps, usePersonaEvents } from '../events'

export const TrackingSectionContainer = ({
  analytics,
  ...props
}: {
  analytics: PersonaRowViewedProps
} & TrackingContainerProps) => {
  const { trackPersonaRowViewed } = usePersonaEvents()
  const handleRowViewed = () => {
    trackPersonaRowViewed({
      rowName: analytics.rowName,
      rowPositionIndex: analytics.rowPositionIndex.toString(),
    })
  }
  return <TrackingContainer onVisible={handleRowViewed} {...props} />
}
